


































































































































































import "ol/ol.css";
import { Vue, Component, Watch } from "vue-property-decorator";
import Feature from "ol/Feature";

import Observable from "ol/Observable";
import Point from "ol/geom/Point";
import Vector from "ol/source/Vector";
import Overlay from "ol/Overlay";
import GeoJSON from "ol/format/GeoJSON.js";
import View from "ol/View";
import Map from "ol/Map";
import Tile from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import OSM from "ol/source/OSM";
import BingMaps from "ol/source/BingMaps";
import Projection from "ol/proj/Projection";
import Cluster from "ol/source/Cluster";

import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import Snap from "ol/interaction/Snap";
import VectorLayer from "ol/layer/Vector";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import Icon from "ol/style/Icon";
import WKT from "ol/format/WKT";
import * as proj from "ol/proj";

import * as easing from "ol/easing";

import ZoomToExtent from "ol/control/ZoomToExtent";
import ScaleLine from "ol/control/ScaleLine";
import control from "ol/control";
import Control from "ol/control/Control";
import MousePosition from "ol/control/MousePosition";
import coordinate from "ol/coordinate";
import Polygon from "ol/geom/Polygon";

import { eventHub } from "@/utils/eventHub";

import { Checkbox } from "element-ui";

import {
  CommonStore,
  IncidentModule,
  MetaModule,
  NotificationModule,
  RouteModule,
} from "@/store/modules";

import Meta from "@/store/modules/meta";
import { Incident, Patrolling } from "@/store/models/incident";
import { Route } from "@/store/models/route";
import { FeatureIcon, FeatureType, IncidentStatus } from "@/utils/constants";
import commonstore from "@/store/modules/common";

import popup from "@/views/incident/subComponents/popup.vue";
import stationpopup from "@/views/incident/subComponents/stationPopup.vue";
import patrolloverlay from "@/views/incident/subComponents/map/patrollOverlay.vue";
import panicmodeoverlay from "@/views/incident/subComponents/map/panicModeOverlay.vue";
import incidentoverlay from "@/views/incident/subComponents/map/incidentOverlay.vue";
import routeoverlay from "@/views/incident/subComponents/map/routeOverlay.vue";
import incident from "../../../../store/api/incident";
import helper from "@/utils/helpers";
import helpers from "@/utils/helpers";
import Gallery from "@/components/UIComponents/gallery.vue";
import Rightdash from "@/views/incident/rightdash.vue";
import { StopData, VehicleList } from "@/store/models/meta";
import Index from "@/views/admin/index.vue";
import { AdminRouter } from "@/utils/routePathContsant";
import SocketHandler from "@/utils/socketHandler";
import IconAnchorUnits from "ol/style/IconAnchorUnits";
import OverlayPositioning from "ol/OverlayPositioning";
import { SourceDestination } from "@/store/models/vechileSchedule";

// import incident from "../../store/api/incident";
var container = document.getElementById("ruleViolationpopup") as any;
var content = document.getElementById("popup-content") as any;
var closer = document.getElementById("popup-closer") as any;

type GeometryType =
  | "Point"
  | "LineString"
  | "LinearRing"
  | "Polygon"
  | "MultiPoint"
  | "MultiLineString"
  | "MultiPolygon"
  | "GeometryCollection"
  | "Circle";

@Component({
  components: {
    Checkbox,
    popup,
    stationpopup,
    patrolloverlay,
    incidentoverlay,
    panicmodeoverlay,
    routeoverlay,
    Gallery,
    Rightdash,
  },
})
export default class MapComponent extends Vue {
  //#region prop
  name = "Initial";
  public isActive: boolean = false;
  public isIncidentLoading = false;
  public category: string = "";
  public district: number = 0;
  public incidents: Incident[] = [];
  public stopData: StopData[] = [];
  public selectedIncidentKey: string = "";
  public checkList: boolean = false;
  public selectedStationInfo: any = {};
  public dialog = false;

  public historyDialog = false;
  public historyRoute: string = "";
  public historyVehicle: string = "";

  public searchVehicle: string = "";
  public showVehicleSearchBar: boolean = false;
  public options: any[] = [] as any;
  public vehicleSearchOptions: any[] = [] as any;
  historySearch: {
    route: number;
    vehicleId: number;
    date: string;
  } = {} as {
    route: number;
    vehicleId: number;
    date: string;
  };

  public selectedMaterialType = "";
  $refs!: {
    mapTarget: InstanceType<typeof HTMLElement>;
    tooltip: InstanceType<typeof HTMLElement>;
    zoomToRoute: InstanceType<typeof HTMLElement>;
    vehicleSearchButton: InstanceType<typeof HTMLElement>;
    vehicleSearchBar: InstanceType<typeof HTMLElement>;
    ruleViolationPopup: InstanceType<typeof HTMLElement>;
    ruleViolationPopupCloser: InstanceType<typeof HTMLElement>;
    ruleViolationPopupContent: InstanceType<typeof HTMLElement>;
  };
  @Watch("selectedIncidentKey")
  onSelectedIncidentKeyChanged(oldVal: any, newVal: any) {
    IncidentModule.setSelectedIncidenId(this.selectedIncidentKey);
  }

  get slectedTab() {
    return IncidentModule.selectedIncidentTab;
  }

  get routes() {
    return MetaModule.routesListOfUser;
  }

  @Watch("traccarFeatures")
  onTraccerFeatureUpdate(newFeatures: any) {
    this.pointVectorSource.clear();
    this.pointVectorSource.addFeatures(newFeatures);
    this.findVehicle("");

    // console.log(newFeatures[0].Speed);
    // console.log("My Style", this.pointVectorLayer.getStyle().setText(this.makeText(`${speed}`)));
    // if (speed)
    // {
    //   this.text.setText(speed.toString());
    // }
    // // this.text.setText(newVal[0].Speed.toString());
  }

  get routeGeoJSON() {
    return MetaModule.routeGeoObj;
  }

  get stopGeoJSON() {
    return MetaModule.stopGeoObj;
  }

  @Watch("routeGeoJSON", { immediate: true, deep: true })
  onRouteChange(newFeatures: any, old: any) {
    // Watcher is used to fix a problem which caused map to use old route when switching route tabs quickly
    if (newFeatures && newFeatures.type) {
      if (this.routeVectorSource) {
        this.routeVectorSource.clear();
      }
      if (this.routeVectorLayer) {
        this.map.removeLayer(this.routeVectorLayer);
      }
      this.routeVectorSource = new Vector({
        features: new GeoJSON().readFeatures(this.routeGeoJSON),
      });
      this.routeVectorLayer = new VectorLayer({
        source: this.routeVectorSource,
        style: (feature) => {
          const color =
            feature === this.selectedRouteFeature ? "green" : "coral";

          return new Style({
            stroke: new Stroke({
              color: color,
              width: 5,
            }),
          });
        },
      });
      this.map.addLayer(this.routeVectorLayer);
      const extent = this.routeVectorSource.getExtent();
      this.map.getView().fit(extent);
      this.routeExtent = extent;
      this.zoomToRoute = new ZoomToExtent({
        className: "zoom-to-route",
        label: "R",
        tipLabel: "Zoom to route",
        extent: this.routeExtent,
      });
      this.map.addControl(this.zoomToRoute);
    }
  }

  @Watch("stopGeoJSON", { immediate: true, deep: true })
  onStopChange(newFeatures: any) {
    if (newFeatures && newFeatures.type) {
      if (this.stopVectorSource) {
        this.stopVectorSource.clear();
      }
      if (this.stopVectorLayer) {
        this.map.removeLayer(this.stopVectorLayer);
      }
      const stopPng = require("@/assets/icons/stop1.png");
      this.stopVectorSource = new Vector({
        features: new GeoJSON().readFeatures(this.stopGeoJSON),
      });
      this.stopVectorLayer = new VectorLayer({
        source: this.stopVectorSource,
        style: new Style({
          image: new Icon({
            color: "rgba(255, 0, 0, .5)",
            crossOrigin: "anonymous",
            src: stopPng,
            scale: 0.04,
            anchor: [0.5, 0.9],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.FRACTION,
          }),
        }),
      });
      this.stopVectorLayer.setZIndex(1);
      this.map.addLayer(this.stopVectorLayer);
    }
  }

  get traccarFeatures() {
    return CommonStore.traccarFeatures;
  }

  get liveMonitoringData() {
    return CommonStore.liveMonitoringData;
  }

  close() {
    this.ruleViolationPopup.setPosition(undefined);
    this.$refs.ruleViolationPopupCloser.blur();
    //closer.blur();
    return false;
  }

  //#endregion

  //#region Map
  map = new Map({});
  osmMap: string = "OSM";
  hide = true;
  popup = new Overlay({});
  ruleViolationPopup = new Overlay({
    element: document.getElementById("ruleViolationpopup") as HTMLElement,
  });
  //popup3 = new Overlay({element : document.getElementById('popup3')});
  stationpPopup = new Overlay({});
  tileLayerList: Tile[] = [];
  projection = new Projection({
    code: "EPSG:4326",
    units: "degrees",
    axisOrientation: "neu",
  });

  bounds: [number, number, number, number] = [
    80.0585566380344,
    26.3478190101467,
    88.2014946472075,
    30.4471622139934,
  ];

  zoomToExtentControl = new ZoomToExtent({
    extent: this.bounds,
  });

  routeExtent: [number, number, number, number] = [
    80.0585566380344,
    26.3478190101467,
    88.2014946472075,
    30.4471622139934,
  ];

  zoomToRoute = new ZoomToExtent({
    label: "R",
    tipLabel: "Zoom to route",
    extent: this.routeExtent,
  });

  mousePositionControl = new MousePosition({
    // coordinateFormat: coordinate.createStringXY(),
    projection: "EPSG:4326",
    undefinedHTML: "",
  });

  incidentFeatureHashMap: Record<string, Feature> = {};
  stopFeatureHashMap: Record<string, Feature> = {};
  incidentOverlayHasMap: Record<string, any> = {};

  patrolFeatureHashMap: Record<string, Feature> = {};
  patrollOverlayHasMap: Record<string, any> = {};

  panicFeatureHashMap: Record<string, Feature> = {};
  panicOverlayHasMap: Record<string, any> = {};

  incidentVectorSource: Vector = new Vector();
  incidentVectorLayer: VectorLayer = new VectorLayer();

  patrolVectorSource: Vector = new Vector();
  patrolVectorLayer: VectorLayer = new VectorLayer();

  panicVectorSource: Vector = new Vector();
  policeStationVectorSource: Vector = new Vector();

  nearestPatrolRouteVectorSource: Vector = new Vector();

  policeStationVectorClusterSource: Cluster = new Cluster({
    source: this.policeStationVectorSource,
  });

  routeVectorSource: Vector = new Vector();
  routeVectorLayer: VectorLayer = new VectorLayer();
  pointVectorSource: Vector = new Vector();
  pointVectorLayer: VectorLayer = new VectorLayer();
  routeVectorClusterSource: Cluster = new Cluster({
    source: this.routeVectorSource,
  });

  stopVectorSource: Vector = new Vector();
  stopVectorLayer: VectorLayer = new VectorLayer();

  ruleViolationPointVectorSource: Vector = new Vector();
  ruleViolationPointVectorLayer: VectorLayer = new VectorLayer();

  selectedRouteFeature: Feature = new Feature();

  mapUpdate = () => {
    //render the map
    //this.updatedIncident();
    //this.updatedPatrolling();
  };

  //#endregion

  //#region get
  get incident() {
    if (this.selectedIncidentKey != "") {
      var incs = IncidentModule.incidents.filter(
        (x) => x.IncidentId == this.selectedIncidentKey
      );

      if (incs.length > 0) {
        return incs[0];
      }
    }

    return undefined;
  }

  getIncidentMaterials(type: string) {
    if (this.incident) {
      return this.incident.IncidentMaterials.filter((x) => x.Type == type).map(
        (x) => x.FileName
      );
    }

    return [];
  }

  get categoryListing() {
    return Meta.incidentCategories;
  }

  get districtListing() {
    return Meta.districtL;
  }

  get nearestPatrollings() {
    return IncidentModule.nearesetPatrollings;
  }
  //#endregion

  //#region vue method---------------------------------

  created() {
    var that = this;
    this.setLayers();
    MetaModule.loadVehicleListDD();

    //Meta.loadIncidentCategory();
    //Meta.loadDis();
    eventHub.$on("mouse-over", this.mouseover);
    eventHub.$on("mouse-out", this.mouseout);
    eventHub.$on(
      "map-update-from-incident-list",
      this.onMapUpdateFromIncidentList
    );

    eventHub.$on("incident-tab-changed", this.onIncidentTabChanged);
    eventHub.$on("tab-changed", this.onTabChanged);
    eventHub.$on("incident-page-changed", this.onIncidentPageChanged);
    eventHub.$on("live-list-clicked", this.onLiveListClicked);
    eventHub.$on("stop-list-clicked", this.onStopListClicked);
    eventHub.$on("section-list-clicked", this.onSectionListClicked);
    eventHub.$on("incident-list-clicked", this.onIncidentListClicked);
    eventHub.$on("acknowledge-incident", this.onAcknowledgeIncident);
    eventHub.$on("reject-incident", this.onRejectIncident);
    eventHub.$on("notification-item-clicked", this.notificationItemClicked);
    eventHub.$on("error", this.handleError);
    eventHub.$on("panic-item-clicked", this.onPanicItemClicked);
    eventHub.$on("map-size-updated", this.onUpdateMapSize);
    eventHub.$on("remove-incident-from-map", this.onRemoveIncidentFromMap);
  }

  createEl(inc: Incident): any {
    var div = document.createElement("DIV");
    var el = document.getElementById("incident_" + inc.IncidentId);
    if (el === null || el === undefined) {
      var inner =
        '<div class="marker_generic marker"  id="incident_' +
        inc.IncidentId +
        '"';
      inner += ' style="background:' + this.loadIcon(inc.Status) + '"> ';
      inner += ' <img src="' + inc.Icon + '" alt="incident icon"></img> </div>';

      div.innerHTML = inner;

      var root = document.getElementById("incidnetOverlay") as any;
      root.appendChild(div);
      el = document.getElementById("incident_" + inc.IncidentId);
    }
    let vm = this;
    (el as any).addEventListener("click", () => {
      vm.onIncidentOverlayClicked(inc);
    });
    return el;
  }

  onUpdateMapSize(e: any) {
    this.updateMapSize();
  }

  handleError(e: any) {
    IncidentModule.setIsPatrollLoading(false);
    IncidentModule.setIsIncidentAssigning(false);
    commonstore.hideLoading();
  }

  makeText = (text: any) => {
    return new Text({
      fill: new Fill({ color: "black" }),
      stroke: new Stroke({ color: "white", width: 2 }),
      text: text,
      textBaseline: "top",
      font: "bold 15px serif",
      offsetY: -40,
    });
  };

  async mounted() {
    this.isIncidentLoading = false;
    this.setMap();
    let tab = IncidentModule.selectedTab;

    // SocketHandler.createConnection(`gps_update/${commonstore.routeId}/`);

    // let routeId = commonstore.routeId;
    //await this.renderRoute(1);
    this.map.on("singleclick", (e: any) => {
      this.map.forEachFeatureAtPixel(
        e.pixel,
        (feature, layer) => {
          let obj: any = feature;

          if (layer === this.stopVectorLayer) {
            var id = feature.get("stopId");
            commonstore.setStopId(id);
            const cord = (feature.getGeometry() as any).flatCoordinates;
            let long = cord[0];
            let lat = cord[1];

            this.onStopOverlayClicked(obj, id, long, lat);
            IncidentModule.setSelectedIncidentTab(IncidentStatus.Assigned);
          } else if (layer === this.routeVectorLayer) {
            var id = feature.get("speedSectionId");
            commonstore.setSectionId(id);
            IncidentModule.setSelectedIncidentTab(IncidentStatus.Acknowledge);
            this.onSectionOverlayClicked(obj, id);
          }
        },
        { hitTolerance: 2 }
      );
    });
    //IncidentModule.getUpdatedPatrollings();
    //IncidentModule.updateTraccarFromSocket(commonstore.traccarUpdates);
    //await IncidentModule.loadIncidents();
    //this.updatedIncident();
    //this.updatedPanicMode();
    //await this.updatedPatrolling();

    this.isIncidentLoading = false;

    // let userProfile = helper.getUserProfile();

    // if (userProfile) {
    //   let extents = helper.convertStringToExtent(userProfile.Extent);
    //   this.district = userProfile.District;
    //   this.map.getView().fit(extents);
    // }
  }

  getSpeed(index: number) {
    //return this.traccarUpdates && this.traccarUpdates[index] && this.traccarUpdates[index].Speed;
  }

  updated() {
    const that = this;
    this.$nextTick(function() {
      // Code that will run only after the entire view has been re-rendered

      const a = NotificationModule.activeNotification;

      if (NotificationModule.activeNotification && !that.isIncidentLoading) {
        const e = NotificationModule.activeNotification;
        that.notificationItemClicked(e);
        NotificationModule.setActiveNotification(null);
      }
    });
  }
  //#endregion//
  renderNearestPatrolRoute() {
    var that = this;
    that.nearestPatrolRouteVectorSource.clear();
    var geojson = IncidentModule.nearesetPatrollings.map((x) => {
      if (x.Geojson) {
        let features = new GeoJSON().readFeatures(x.Geojson);
        that.nearestPatrolRouteVectorSource.addFeatures(features);
      }
    });
  }

  async renderRoute(e: any) {
    // Routes and Stops are set by watcher
    await Meta.loadRouteGeoObj({ routeId: e });
    await Meta.loadStopGeoObj({ routeId: e });

    // this.stopVectorSource = new Vector({
    //   features: new GeoJSON().readFeatures(this.stopGeoJSON),
    // });

    const stopPng = require("@/assets/icons/stop1.png");
    const busPng = require("@/assets/icons/bus.png");
    const arrowPng = require("@/assets/icons/arrow.svg");
    let id = CommonStore.featureVehicleId;
    const busStyle = (bus_id: any) => {
      let fid = id;
      return (feature: any) => {
        const driverInfo = feature.get("driver");
        const rotationInDegree = feature.get("course");
        const rotation = rotationInDegree
          ? (rotationInDegree / 180) * Math.PI
          : 0;
        let m = `${feature.get("vehicleNumber")} \n ${Number(
          feature.get("speed")
        ).toFixed(2)}kmph`;
        if (feature.get("vehicleId") === id) {
          m = `\n${feature.get("vehicleNumber")} \n ${Number(
            feature.get("speed")
          ).toFixed(2)}kmph \n ${
            driverInfo && driverInfo.driverName
              ? driverInfo.driverName + "\n"
              : ""
          }
           ${
             driverInfo && driverInfo.mobileNumber
               ? driverInfo.mobileNumber + "\n"
               : ""
           }
           ${
             driverInfo && driverInfo.companyName
               ? driverInfo.companyName + "\n"
               : ""
           } ${Number(feature.get("acceleration")).toFixed(2)}mps²`;
        }
        const bus = new Style({
          image: new Icon({
            color: "rgba(255, 0, 0, .5)",
            crossOrigin: "anonymous",
            src: busPng,
            // rotation,
            scale: 0.15,
          }),
          text: new Text({
            text: m,
            offsetY: -50,
            font: "Bold 10px Arial",
            fill: new Fill({ color: "#000" }),
            stroke: new Stroke({ color: "#fff", width: 3 }),
          }),
        });
        const arrow = new Style({
          image: new Icon({
            crossOrigin: "anonymous",
            src: arrowPng,
            scale: 0.8,
            rotation,
          }),
        });
        return [arrow, bus];
      };
    };
    this.map.on("click", function(e) {
      map.forEachFeatureAtPixel(e.pixel, function(feature, layer) {
        id = feature.get("vehicleId");
        CommonStore.setFeatureVehicleId(id);
        busStyle(id);
      });
    });
    // const busStyle = (feature: any) => {
    //   const rotationInDegree = feature.get("course");
    //   const rotation = rotationInDegree
    //     ? (rotationInDegree / 180) * Math.PI
    //     : 0;
    //   const m = `${feature.get("vehicleNumber")} \n ${Number(
    //     feature.get("speed")
    //   ).toFixed(2)}kmph`;
    //   const m1 = `${feature.get("vehicleNumber")} \n ${Number(
    //     feature.get("speed")
    //   ).toFixed(2)}kmph \n ${feature.get("driverName") ? null : ""} \n ${Number(
    //     feature.get("acceleration")
    //   ).toFixed(2)}mps²`;
    //   const bus = new Style({
    //     image: new Icon({
    //       color: "rgba(255, 0, 0, .5)",
    //       crossOrigin: "anonymous",
    //       src: busPng,
    //       scale: 0.15,
    //     }),
    //     text: new Text({
    //       text: m,
    //       offsetY: -50,
    //       font: "Bold 10px Arial",
    //       fill: new Fill({ color: "#000" }),
    //       stroke: new Stroke({ color: "#fff", width: 3 }),
    //     }),
    //   });

    //   const arrow = new Style({
    //     image: new Icon({
    //       crossOrigin: "anonymous",
    //       src: arrowPng,
    //       scale: 0.8,
    //       rotation,
    //     }),
    //   });
    //   return [arrow, bus];
    // };
    //this.map.removeLayer(this.pointVectorLayer)
    this.pointVectorLayer.setStyle(busStyle(id));
    this.pointVectorLayer.setSource(this.pointVectorSource);
    this.pointVectorLayer.setZIndex(1);
    this.map.addLayer(this.pointVectorLayer);
    var map = this.map;

    map.on("pointermove", (event: any) => {
      let noFeatureFound = true;
      map.forEachFeatureAtPixel(
        event.pixel,
        (feature, layer) => {
          if (layer === this.routeVectorLayer) {
            noFeatureFound = false;
            this.placeHoverInfo(
              event.pixel,
              `${feature.get("linkName")} <br /> ${feature.get(
                "speedLimit"
              )} kmph `
            );
            //map.getTargetElement().setAttribute('title', `Speed limit: ${feature.get('speed_limit')}`);
          } else if (layer === this.pointVectorLayer) {
            noFeatureFound = false;
            //this.placeHoverInfo(event.pixel, `Speed: ${feature.get("Speed")}`);
            //map.getTargetElement().setAttribute('title', `Speed: ${tc.Speed}`);
          } else if (layer === this.stopVectorLayer) {
            noFeatureFound = false;
            this.placeHoverInfo(
              event.pixel,
              `Bus Stop: ${feature.get("name")}`
            );
          }
        },
        { hitTolerance: 1 }
      );
      if (noFeatureFound) {
        this.hideHoverInfo();
      }
    });

    const VehicleSearchControl = new Control({
      element: this.$refs.vehicleSearchBar,
    });
    this.map.addControl(VehicleSearchControl);
  }

  placeHoverInfo(pixel: any, innerHTML: any) {
    const tooltip: any = this.$refs.tooltip;
    tooltip.style.display = "block";
    tooltip.style.left = `${parseInt(pixel[0])}px`;
    tooltip.style.top = `${parseInt(pixel[1])}px`;
    tooltip.style.opacity = 1;
    tooltip.innerHTML = innerHTML;
    // alert(`${tooltip}, ${pixel}, ${innerHTML}`);
  }

  hideHoverInfo() {
    const tooltip: any = this.$refs.tooltip;
    tooltip.style.opacity = 0;
  }

  async renderPoliceStations() {
    await Meta.loadPolicStationGeoObj();

    var geojsonObject = Meta.policeStationGeoObj;

    this.policeStationVectorSource = new Vector({
      features: new GeoJSON().readFeatures(geojsonObject),
    });
    this.policeStationVectorClusterSource = new Cluster({
      distance: 100,
      source: this.policeStationVectorSource,
    });
    const ic = require("@/assets/station.svg");

    var styleCache: any = {};
    const vectorLayer = new VectorLayer({
      source: this.policeStationVectorClusterSource,
      style: function(feature) {
        var size = feature.get("features").length;
        var style = styleCache[size];
        if (!style) {
          style = new Style({
            image: new Icon({
              src: ic,
              rotateWithView: true,
              // rotation: this.get('angle') * Math.PI / 180,
              anchor: [0.5, 0.5],
              anchorXUnits: IconAnchorUnits.FRACTION,
              anchorYUnits: IconAnchorUnits.FRACTION,
              opacity: 1,
            }),
            text: new Text({
              text: size.toString(),
              offsetX: 0,
              offsetY: -20,
              font: "Bold 15px Arail",
              fill: new Fill({
                color: "#000",
              }),
            }),
          });
          styleCache[size] = style;
        }
        return style;
      },
    });

    this.map.addLayer(vectorLayer);
  }

  loadIcon(type: string) {
    const path = require("@/assets/" + type + ".svg");
    const url = "url(" + path + ") no-repeat center center";
    return url;
  }

  async onAcknowledgeIncident(e: any) {
    await IncidentModule.setAcknowledgeIncident(e);
    eventHub.$emit("incident-status-updated", e);
    this.popup.setPosition(undefined);
    this.$snotify.success("successfully acknowledged");
  }

  async onRejectIncident(e: any) {
    await IncidentModule.setRejectIncident(e);
    eventHub.$emit("incident-status-updated", e);
    this.popup.setPosition(undefined);
    this.$snotify.success("rejected");
  }

  mouseover(e: any) {
    const el = document.getElementById("incident_" + e) as any;
    if (el) {
      el.classList.add("markerHovered");
    }
  }

  mouseout(e: any) {
    const el = document.getElementById("incident_" + e) as any;
    if (el) {
      el.classList.remove("markerHovered");
    }
  }

  onRemoveIncidentFromMap(e: any) {
    if (this.incidentFeatureHashMap[e]) {
      //this.incidentVectorSource.ex

      this.map.removeOverlay(this.incidentOverlayHasMap[e]);
      this.incidentVectorSource.removeFeature(this.incidentFeatureHashMap[e]);

      delete this.incidentOverlayHasMap[e];
      delete this.incidentFeatureHashMap[e];
    }
  }

  onIncidentOverlayClicked(incident: any) {
    if (this.selectedIncidentKey != "") {
      this.beforeSelection(this.selectedIncidentKey);
    }
    this.setAsSelected(incident.IncidentId);

    const center = (proj as any).fromLonLat(
      [incident.IncidentLong, incident.IncidentLat],
      this.projection
    );

    this.map.getView().animate({
      center: center,
      duration: 2000,
      zoom: 15,
    });

    eventHub.$emit("scroll-to-active-incident", { index: incident.IncidentId });
  }

  onStopOverlayClicked(stopData: any, id: any, long: any, lat: any) {
    const center = (proj as any).fromLonLat([long, lat], this.projection);

    this.map.getView().animate({
      center: center,
      duration: 2000,
      zoom: 15,
    });
    eventHub.$emit("scroll-to-active-stop", { index: id });
  }

  onSectionOverlayClicked(sectionData: Feature, id: any) {
    this.selectedRouteFeature = sectionData;
    //  const center = (proj as any).fromLonLat(
    //     [long, lat],
    //     this.projection
    //   );
    const geometry = sectionData.getGeometry();
    // this.map.getView().animate({
    //   center: center,
    //   duration: 2000,
    //   zoom: 15
    // });
    if (geometry) {
      const extent = geometry.getExtent();
      this.map.getView().fit(extent);
    }
    //eventHub.$emit("scroll-to-active-section", { index : id });
  }

  onIncidentListClicked(e: any) {
    this.selectIncident(e);

    /*
     const center = (proj as any).fromLonLat(
      [incident.IncidentLong, incident.IncidentLat],
      this.projection
    );

    this.map.getView().animate({
      center: center,
      duration: 2000,
      zoom: 15
    });
    */
  }

  onPanicItemClicked(e: any) {
    const obj = IncidentModule.panicModes[e];
    const center = (proj as any).fromLonLat(
      [obj.Longitude, obj.Latitude],
      this.projection
    );
    this.map.getView().animate({
      center: center,
      duration: 2000,
      zoom: 15,
    });
  }

  async onStopListClicked(e: any) {
    await Meta.loadStopdata();
    var obj1 = Meta.stopData;

    const obj = obj1.filter((x) => x.gid == e);

    const center = (proj as any).fromLonLat(
      [obj[0].longitude, obj[0].latitude],
      this.projection
    );
    this.map.getView().animate({
      center: center,
      duration: 2000,
      zoom: 15,
    });
  }

  onLiveListClicked(e: any) {
    let id = e;
    let cord = e.location.coordinates;
    this.ruleViolationPointVectorSource.clear();
    var iconFeature = new Feature({
      geometry: new Point(cord),
    });
    this.map.getView().animate({
      center: cord,
      zoom: 15,
      duration: 2000,
    });

    let c = (iconFeature.getGeometry() as any).flatCoordinates;

    const violationPng = require("@/assets/location1.svg");
    this.ruleViolationPointVectorSource.addFeature(iconFeature);
    this.ruleViolationPointVectorLayer = new VectorLayer({
      source: this.ruleViolationPointVectorSource,
      style: new Style({
        image: new Icon({
          color: "rgba(255, 0, 0, .5)",
          crossOrigin: "anonymous",
          src: violationPng,
          scale: 0.14,
          anchor: [0.5, 0.9],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
        }),
      }),
    });

    this.ruleViolationPointVectorLayer.setZIndex(1);
    this.map.addLayer(this.ruleViolationPointVectorLayer);
    const conte: any = this.$refs.ruleViolationPopupContent;
    conte.innerHTML = `<p>` + e.title + `</p><code>` + e.message + `</code>`;
    this.ruleViolationPopup.setPosition(c);

    // if (typeof id != "number") {
    //   id = e.deviceId;
    // }
    // const pointFeatures = this.pointVectorSource.getFeatures();
    // const selectedVehicle = pointFeatures.find(
    //   (ftr) => ftr.get("deviceId") == id
    // );
    // if (selectedVehicle) {
    //   this.selectedRouteFeature = selectedVehicle;

    //   const geometry = this.selectedRouteFeature.getGeometry();
    //   if (geometry) {
    //     const extent = geometry.getExtent();
    //     this.map.getView().fit(extent);
    //   }
    // } else {
    //   console.log(selectedVehicle, "not found");
    // }
  }

  onVehicleSearchClicked(e: any) {
    let id = e;

    if (typeof id != "number") {
      id = e.deviceId;
    }
    const pointFeatures = this.pointVectorSource.getFeatures();
    const selectedVehicle = pointFeatures.find(
      (ftr) => ftr.get("deviceId") == id
    );
    if (selectedVehicle) {
      this.selectedRouteFeature = selectedVehicle;

      const geometry = this.selectedRouteFeature.getGeometry();
      if (geometry) {
        const extent = geometry.getExtent();
        this.map.getView().fit(extent);
      }
    } else {
      console.log(selectedVehicle, "not found");
    }
  }

  async onSectionListClicked(e: any) {
    let id = e;
    const routeFeatures = this.routeVectorSource.getFeatures();
    const selectedRoute = routeFeatures.find(
      (ftr) =>
        ftr.get("roadId") === e.roadId &&
        ftr.get("speedSectionId") === e.sectionId
    );
    if (selectedRoute) {
      this.selectedRouteFeature = selectedRoute;
      // await Meta.loadRouteGeoObjBySection(e);
      // var obj1  : any= Meta.routeGeoObj;
      //
      // var p = obj1.features[0].geometry.coordinates[0];
      // var f = p[0];
      // var l= p[p.length-1];
      // console.log(obj1.features);
      // // this.selectedRouteFeature= obj1.features
      //   const center = (proj as any).fromLonLat(
      //     [f[0], f[1],l[0],l[1]],
      //     this.projection
      //   );
      const geometry = this.selectedRouteFeature.getGeometry();
      if (geometry) {
        const extent = geometry.getExtent();
        this.map.getView().fit(extent);
        // this.animateToExtent(extent);
        // this.map.getView().animate({
        //   center: center,
        //   duration: 2000,
        //   zoom: 15
        // });
      }
    } else {
      console.log(selectedRoute, "not found");
    }
  }

  async selectIncident(id: string) {
    var incidents = IncidentModule.incidents.filter((x) => x.IncidentId == id);

    var incident = {} as Incident;
    if (incidents.length > 0) {
      incident = incidents[0];
    } else {
      return;
    }

    if (incident.Status !== IncidentModule.selectedIncidentTab) {
      //
      eventHub.$emit("change-tab", incident.Status);
      await this.onIncidentTabChanged(incident.Status);
    }

    this.setAsSelected(id);

    const center = (proj as any).fromLonLat(
      [incident.IncidentLong, incident.IncidentLat],
      this.projection
    );

    this.map.getView().animate({
      center: center,
      duration: 2000,
      zoom: 15,
    });
    NotificationModule.removeNotification(id);
  }

  onMaterialSectionClicked(e: any) {
    this.selectedMaterialType = e;
    this.dialog = true;
  }

  onUpdateMap(e: Incident[]) {
    this.updatedIncident();
  }

  async onMapUpdateFromIncidentList(e: any) {
    //this.popup.setPosition(undefined);

    IncidentModule.setSelectedIncidentTab(e);
    IncidentModule.setSelectedTab(e);
    // await IncidentModule.updateIncidentOnTabChange();

    this.incidentVectorSource.clear();
    this.nearestPatrolRouteVectorSource.clear();
    this.incidentFeatureHashMap = {};

    for (var key in this.incidentOverlayHasMap) {
      this.map.removeOverlay(this.incidentOverlayHasMap[key]);
    }
    this.incidentOverlayHasMap = {};
    this.updatedIncident();
  }

  requestId = 0;
  updateSize() {
    this.map.updateSize();
    this.requestId = requestAnimationFrame(this.updateSize);
  }

  stopUpdateAnimation() {
    cancelAnimationFrame(this.requestId);
  }

  updateMapSize() {
    this.stopUpdateAnimation();
    setTimeout(() => {
      this.updateSize();
    }, 300);

    setTimeout(() => {
      this.stopUpdateAnimation();
    }, 600);
  }

  async onIncidentTabChanged(e: any) {
    this.popup.setPosition(undefined);
    this.updateMapSize();

    IncidentModule.setSelectedIncidentTab(e);
    //   IncidentModule.setSelectedTab(e);
    //   this.stopVectorSource.clear();
    //   this.pointVectorSource.clear();
    //   this.routeVectorSource.clear();
    //   this.map.removeLayer(this.routeVectorLayer);
    //   this.map.removeLayer(this.pointVectorLayer);
    //   this.map.removeLayer(this.stopVectorLayer);
    //   if (e === "route1") {
    //     commonstore.setRouteId(1);
    //     await this.renderRoute(1);
    //   } else if (e === "route2") {
    //     commonstore.setRouteId(2);
    //     await this.renderRoute(2);
    //   } else if (e === "route3") {
    //     commonstore.setRouteId(3);
    //     await this.renderRoute(3);
    //   }
  }

  async onTabChanged(e: any) {
    this.popup.setPosition(undefined);
    this.updateMapSize();

    //IncidentModule.setSelectedIncidentTab(e);
    IncidentModule.setSelectedTab(e);
    // this.stopVectorSource.clear();
    this.pointVectorSource.clear();
    // this.routeVectorSource.clear();
    // this.map.removeLayer(this.routeVectorLayer);
    this.map.removeLayer(this.pointVectorLayer);
    // this.map.removeLayer(this.stopVectorLayer);
    commonstore.setRouteId(e);
    await this.renderRoute(e);
  }

  onUpdatePatrolFromSocket(e: any) {
    //this.updatedPatrolling();
  }

  onUpdatePanicModeFromSocket(e: any) {
    //this.updatedPanicMode();
  }

  onIncidentPageChanged(e: Incident[]) {
    this.closePopup({});
    this.incidentVectorSource.clear();
    this.nearestPatrolRouteVectorSource.clear();
    this.incidentFeatureHashMap = {};

    for (var key in this.incidentOverlayHasMap) {
      this.map.removeOverlay(this.incidentOverlayHasMap[key]);
    }

    this.incidentOverlayHasMap = {};

    e.map((x) => {
      this.addIncidentToMap(x);
    });
  }

  notificationItemClicked(e: any) {
    //if (e.Type === "incident") {
    //load incidents-->>

    this.selectIncident(e.Id);
    //}
  }

  async updatedIncident() {
    var that = this;
    var newFeatures: Feature[] = [];
    let data = IncidentModule.updatedIncidents;

    for (var k in data) {
      this.addIncidentToMap(data[k]);
    }
  }

  addIncidentToMap(incident: Incident) {
    const x = incident;
    if (!x) {
      return;
    }

    if (x.IncidentLong && x.IncidentLat) {
      const feature = [
        parseFloat(x.IncidentLong),
        parseFloat(x.IncidentLat),
      ] as [number, number];
      var iconFeature = new Feature({
        geometry: new Point(feature),
      });

      const cord = (iconFeature.getGeometry() as any).flatCoordinates;

      //insert new incident
      //
      if (this.slectedTab == x.Status) {
        iconFeature.setProperties({
          id: x.IncidentId,
          type: FeatureType.Incident,
        });

        this.incidentFeatureHashMap[x.IncidentId] = iconFeature;

        //add incident on the basis of selected tab
        this.incidentVectorSource.addFeature(
          this.incidentFeatureHashMap[x.IncidentId]
        );

        this.incidentOverlayHasMap[x.IncidentId] = new Overlay({
          position: undefined,
          positioning: OverlayPositioning.CENTER_CENTER,
          element: this.createEl(x) as HTMLElement,
        });

        this.map.addOverlay(this.incidentOverlayHasMap[x.IncidentId]);

        this.incidentOverlayHasMap[x.IncidentId].setPosition(cord);
      }
    }
  }

  async updatedPatrolling() {
    var that = this;
    var newFeatures: Feature[] = [];

    const inc = await IncidentModule.updatedPatrollings.map((x) => {
      if (x.Latitude && x.Longitude) {
        const feature = [parseFloat(x.Longitude), parseFloat(x.Latitude)] as [
          number,
          number
        ];
        var iconFeature = new Feature({
          geometry: new Point(feature),
        });
        const cord = (iconFeature.getGeometry() as any).flatCoordinates;

        if (this.patrolFeatureHashMap[x.PatrollingId]) {
          if (!x.Status) {
            //if need to delete
            this.patrolVectorSource.removeFeature(
              this.patrolFeatureHashMap[x.PatrollingId]
            );
            this.map.removeOverlay(this.patrollOverlayHasMap[x.PatrollingId]);
            delete this.patrollOverlayHasMap[x.PatrollingId];
            delete this.patrolFeatureHashMap[x.PatrollingId];
          } else {
            //update feature
            this.patrolFeatureHashMap[x.PatrollingId].setGeometry(
              new Point(feature)
            );

            this.patrollOverlayHasMap[x.PatrollingId].setPosition(cord);
          }
        } else {
          //insert feature
          if (x.Status) {
            //if status is false then no need to add here

            //  iconFeature.setStyle(this.getFeatureStyle(FeatureIcon.Patrol));
            iconFeature.setProperties({
              id: x.PatrollingId,
              type: FeatureType.Patrol,
            });
            //
            this.patrolFeatureHashMap[x.PatrollingId] = iconFeature;
            this.patrolVectorSource.addFeature(
              this.patrolFeatureHashMap[x.PatrollingId]
            );

            /// create overlay for the feature

            this.patrollOverlayHasMap[x.PatrollingId] = new Overlay({
              position: undefined,
              positioning: OverlayPositioning.CENTER_CENTER,
              element: document.getElementById(
                "overlay_" + x.PatrollingId
              ) as HTMLElement,
              stopEvent: false,
            });
            this.map.addOverlay(this.patrollOverlayHasMap[x.PatrollingId]);

            this.patrollOverlayHasMap[x.PatrollingId].setPosition(cord);
            //
          }
        }
      }
    });
  }

  async updatedPanicMode() {
    var that = this;
    var newFeatures: Feature[] = [];

    for (var key in IncidentModule.panicModes) {
      const x = IncidentModule.panicModes[key];
      if (x.Latitude && x.Longitude) {
        const feature = [parseFloat(x.Longitude), parseFloat(x.Latitude)] as [
          number,
          number
        ];
        var iconFeature = new Feature({
          geometry: new Point(feature),
        });
        const cord = (iconFeature.getGeometry() as any).flatCoordinates;

        if (this.panicFeatureHashMap[x.PanicModeId]) {
          if (!x.Status) {
            //if need to delete
            this.panicVectorSource.removeFeature(
              this.panicFeatureHashMap[x.PanicModeId]
            );
            this.map.removeOverlay(this.panicOverlayHasMap[x.PanicModeId]);
            delete this.panicOverlayHasMap[x.PanicModeId];
            delete this.panicFeatureHashMap[x.PanicModeId];
            IncidentModule.removePanicMode(x.PanicModeId);
          } else {
            //update feature
            this.panicFeatureHashMap[x.PanicModeId].setGeometry(
              new Point(feature)
            );

            this.panicOverlayHasMap[x.PanicModeId].setPosition(cord);
          }
        } else {
          //insert feature
          if (x.Status) {
            //if status is false then no need to add here

            //  iconFeature.setStyle(this.getFeatureStyle(FeatureIcon.Patrol));
            iconFeature.setProperties({
              id: x.PanicModeId,
              type: FeatureType.Panic,
            });
            //
            this.panicFeatureHashMap[x.PanicModeId] = iconFeature;
            this.panicVectorSource.addFeature(
              this.panicFeatureHashMap[x.PanicModeId]
            );

            /// create overlay for the feature

            this.panicOverlayHasMap[x.PanicModeId] = new Overlay({
              position: undefined,
              positioning: OverlayPositioning.CENTER_CENTER,
              element: document.getElementById(
                "panic_overlay_" + x.PanicModeId
              ) as HTMLElement,
              stopEvent: false,
            });
            this.map.addOverlay(this.panicOverlayHasMap[x.PanicModeId]);

            this.panicOverlayHasMap[x.PanicModeId].setPosition(cord);
            //
          }
        }
      }
    }
  }

  getFeatureStyle(icon: string) {
    const style = new Style({
      image: new Icon({
        rotateWithView: true,
        // rotation: this.get('angle') * Math.PI / 180,
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        opacity: 1,
      }),
    });
    return style;
  }

  closePopup(e: any) {
    this.popup.setPosition(undefined);
    this.stationpPopup.setPosition(undefined);
  }

  getLayer(layerID: string) {
    const layers = this.map.getLayers().getArray();
    let l = layers[0] as Tile;
    for (const layer of layers) {
      if (layer.get("id") === layerID) {
        l = layer as Tile;
        return l;
      }
    }
    return null;
  }

  async setAsSelected(key: string) {
    // var vectorContext = event.vectorContext;

    if (this.incidentFeatureHashMap[key]) {
      var feature = this.incidentFeatureHashMap[key];

      if (feature) {
        //  feature.setStyle(this.getFeatureStyle(FeatureIcon.Patrol));

        const cord = (feature.getGeometry() as any).flatCoordinates;
        var prop = feature.getProperties();
        const ftype = FeatureType.Incident;

        if ((prop.type as FeatureType) === ftype) {
          await this.loadNearestPatrollTroops(key);
          let routeId = commonstore.routeId;
          await this.renderRoute(routeId);
          this.renderNearestPatrolRoute();
          this.popup.setPosition(cord);
          this.selectedIncidentKey = prop.id; //key
          this.afterSelection(key);
          this.popup.setPosition(cord);
        }
      }
    }
  }

  setAsUnselected(key: string) {
    if (this.incidentFeatureHashMap[key]) {
      // var vectorContext = event.vectorContext;
      this.incidentFeatureHashMap[key].setStyle(
        this.getFeatureStyle(FeatureIcon.Incident)
      );
    }
  }

  setLayers() {
    let osm = new Tile({
      visible: true,
      source: new OSM(),
    });
    osm.set("id", "OSM");
    this.tileLayerList.push(osm);
    let bing = new Tile({
      visible: false,
      source: new BingMaps({
        key: "ApTJzdkyN1DdFKkRAE6QIDtzihNaf6IWJsT-nQ_2eMoO4PN__0Tzhl2-WgJtXFSp",
        imagerySet: "AerialWithLabels",
      }),
    });
    bing.set("id", "BingMap");
    this.tileLayerList.push(bing);
  }

  scaleControl() {
    let ctrl = new ScaleLine({
      units: "metric",
    });

    return ctrl;
  }

  createStringXY(fraction = 4): any {
    return (coordinate: any) => {
      if (coordinate) {
        let template = "{x}, {y}";
        return template
          .replace("{x}", coordinate[0].toFixed(fraction))
          .replace("{y}", coordinate[1].toFixed(fraction));
      }
    };
  }

  setMap() {
    this.map = new Map({
      view: new View({
        center: [83.6596, 28.4011],
        //   center: [0, 0],
        zoom: 6,
        maxZoom: 19,
        projection: this.projection,
      }),
      target: this.$refs.mapTarget,
    });

    this.map.addControl(this.zoomToExtentControl);
    this.map.addControl(
      new ScaleLine({
        units: "metric",
      })
    );
    this.map.addControl(
      new MousePosition({
        coordinateFormat: this.createStringXY(),
        projection: "EPSG:4326",
      })
    );
    this.ruleViolationPopup = new Overlay({
      element: this.$refs.ruleViolationPopup,
    });
    this.map.addOverlay(this.popup);
    this.map.addOverlay(this.stationpPopup);
    this.map.addOverlay(this.ruleViolationPopup);
    this.popup.setPosition(undefined);
    this.stationpPopup.setPosition(undefined);

    var el = document.getElementById("popup") as HTMLElement;
    this.popup.setElement(el);

    this.map.on("click", async (evt) => {
      var element = this.popup.getElement();
      const e = evt as any;
      const coordinate = [e.coordinate[0], e.coordinate[1]] as [number, number];

      var feature = this.map.forEachFeatureAtPixel(e.pixel, function(feature) {
        return feature;
      });

      if (feature) {
        const cord = (feature.getGeometry() as any).flatCoordinates;
        var prop = feature.getProperties();

        if (!prop.features) {
          //incident click
          return;
          if (this.selectedIncidentKey != "") {
            this.beforeSelection(this.selectedIncidentKey);
          }
          if ((prop.type as FeatureType) == FeatureType.Incident) {
            this.setAsSelected(prop.id);
          }
        } else if (
          (prop.features[0].values_.type as FeatureType) == FeatureType.Station
        ) {
          //station click
          var featureCount = prop.features.length;
          var clusterLocationCoordinates = this.stationpPopup.setPosition(cord);
          if (featureCount > 1) {
            this.selectedStationInfo = [];
            for (let feat of prop.features) {
              var currentFeature = feat.value_;
              this.selectedStationInfo.push({
                name: feat.values_.name,
                office_typ: feat.values_.office_type,
                place_name: feat.values_.place_name,
                eng_nam: feat.values_.eng_nam,
                phone1: "",
                phone2: "",
                phone3: "",
                Landline: "",
              });
            }
          } else {
            this.selectedStationInfo = [
              {
                name: [prop.features[0].values_.name],
                office_typ: prop.features[0].values_.office_type,
                place_name: prop.features[0].values_.place_name,
                eng_nam: prop.features[0].values_.eng_nam,
                phone1: prop.features[0].values_.incharge_phone_number,
                phone2: prop.features[0].values_.duty_office_phone_number,
                phone3: prop.features[0].values_.phone_number,
                Landline: prop.features[0].values_.landline,
              },
            ];
          }

          // var clusterPointLocation = prop.geometry.flatCoordinates;
        }
      }
    });

    for (const layer of this.tileLayerList) {
      this.map.addLayer(layer);
    }

    //set vector layer
    this.incidentVectorSource = new Vector({
      features: [],
    });
    this.incidentVectorLayer = new VectorLayer({
      source: this.incidentVectorSource,
    });
    var that = this;
    // this.incidentVectorSource.on("addfeature", function(e: any) {
    //   that.flash(e.feature);
    // });

    this.patrolVectorSource = new Vector({
      features: [],
    });
    this.patrolVectorLayer = new VectorLayer({
      source: this.patrolVectorSource,
    });

    this.panicVectorSource = new Vector({
      features: [],
    });

    const panicVectorLayer = new VectorLayer({
      source: this.panicVectorSource,
    });

    this.nearestPatrolRouteVectorSource = new Vector({
      features: [],
    });
    const nearestPatrolRouteVectorLayer = new VectorLayer({
      source: this.nearestPatrolRouteVectorSource,
      style: new Style({
        stroke: new Stroke({
          color: "#df2020",
          width: 4,
        }),
      }),
    });

    this.map.addLayer(this.incidentVectorLayer);
    this.map.addLayer(this.patrolVectorLayer);
    this.map.addLayer(panicVectorLayer);
    this.map.addLayer(nearestPatrolRouteVectorLayer);
  }

  beforeSelection(selectedKey: string) {
    // this.incidentFeatureHashMap[selectedKey].setStyle(
    //   this.getFeatureStyle(FeatureIcon.Incident)
    // );
  }

  afterSelection(selectedKey: string) {
    // this.incidentFeatureHashMap[selectedKey].setStyle(
    //   this.getFeatureStyle(FeatureIcon.Patrol)
    // );
    //const incident = IncidentModule.incidents[selectedKey];
    // let lat = incident.IncidentLat;
    // let lng = incident.IncidentLong;
  }

  async loadNearestPatrollTroops(key: string) {
    IncidentModule.setIsPatrollLoading(true);
    let incidents = IncidentModule.incidents.filter((x) => x.IncidentId == key);

    if (incidents.length > 0) {
      let incident = incidents[0];
      IncidentModule.loadIncidentMaterials(key);
      const res = await IncidentModule.loadNearestPatrolling({
        lat: incident.IncidentLat,
        lng: incident.IncidentLong,
      });
    }
    IncidentModule.setIsPatrollLoading(false);
  }

  async assignIncident(patrollingId: string) {
    IncidentModule.setIsIncidentAssigning(true);

    let patrollObj = IncidentModule.patrollings[patrollingId];
    if (!patrollObj) {
      IncidentModule.setIsIncidentAssigning(false);
      this.$notify.error(
        "partolling:" + patrollingId + " could not locate in map"
      );
      return;
    }
    const { UserId } = patrollObj;
    let a = IncidentModule.patrollings[patrollingId];

    const obj = {
      IncidentId: this.selectedIncidentKey,
      PatrollingId: patrollingId,
      AssignedTo: UserId,
    };

    await IncidentModule.assignIncident(obj);
    eventHub.$emit("incident-status-updated", this.selectedIncidentKey);

    this.$snotify.success("assigned successfully");
    IncidentModule.setIsIncidentAssigning(false);
    this.popup.setPosition(undefined);
  }

  public switchMap(layer: string) {
    const osm = this.getLayer("OSM") as Tile;
    osm.setVisible(false);
    const bing = this.getLayer("BingMap") as Tile;
    bing.setVisible(false);
    if (layer != "None") {
      const mapLayer = this.getLayer(layer) as Tile;
      if (mapLayer != null) {
        mapLayer.setVisible(true);
      }
    }
  }

  clickHistory() {
    var el1 = document.getElementById("stationpopup") as HTMLElement;
    this.stationpPopup.setElement(el1);
    this.stationpPopup.setPosition([83.6596, 28.4011]);
    this.map.addOverlay(this.stationpPopup);
  }

  updateVisibility(layerId: string, visible: boolean) {
    for (let i = 0; i < this.tileLayerList.length; i++) {
      if (layerId == this.tileLayerList[i].getProperties().id) {
        this.tileLayerList[i].setVisible(visible);
        break;
      }
    }
  }

  toggleHide() {
    this.hide = !this.hide;
    this.updateMapSize();
  }

  resizeMap() {
    this.updateMapSize();
  }

  findVehicle(query: any) {
    if (query !== "") {
      const vehicles = this.traccarFeatures
        .filter(
          (feature) => feature.getProperties().routeId == CommonStore.routeId
        )
        .map((feature) => feature.getProperties());
      // setTimeout(() => {
      this.options = vehicles.filter((vehicle) => {
        return (
          vehicle.vehicleNumber.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
      // }, 200);
    } else {
      this.options = [];
    }
  }

  findAllVehicle(query: any) {
    if (query !== "") {
      const vehicles = MetaModule.vehicleList;
      // setTimeout(() => {
      this.vehicleSearchOptions = vehicles.filter((vehicle) => {
        return (
          vehicle.vehicleNumber.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
      // }, 200);
    } else {
      this.vehicleSearchOptions = [];
    }
  }
  toggleVehicleSearchBar() {
    this.showVehicleSearchBar = !this.showVehicleSearchBar;
    this.searchVehicle = "";
  }
  beforeDestroy() {
    // SocketHandler.closeConnection();
    this.map.dispose();
  }
}
