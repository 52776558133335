










































import { Component, Vue, Watch } from "vue-property-decorator";
import incidentListUI from "@/views/incident/subComponents/incidentListUI.vue";
import MainMap from "@/views/incident/subComponents/map/mainMap.vue";
import sectionList from "@/views/incident/subComponents/sectionList.vue";
import stopList from "@/views/incident/subComponents/stopList.vue";
import liveMonitoringList from "@/views/incident/subComponents/liveMonitoringList.vue";
import assigned from "@/views/incident/subComponents/assigned.vue";
import rejected from "@/views/incident/subComponents/rejected.vue";
import Rightdash from "@/views/incident/rightdash.vue";
import IncidentModule from "@/store/modules/incident";
import { IncidentStatus } from "@/utils/constants";
import incident from "@/store/api/incident";
import { eventHub } from "@/utils/eventHub";
import MessagingModule from "@/store/modules/messaging";
import { MetaModule } from "@/store/modules";

@Component({
  components: {
    MainMap,
    incidentListUI,
    sectionList,
    stopList,
    assigned,
    rejected,
    Rightdash,
    liveMonitoringList,
  },
})
export default class Maindash extends Vue {
  name = "Dashboard";
  isActive: boolean = false;
  isVisible: boolean = false;
  complainOrIncident: any = "incident";

  activeName = IncidentModule.selectedIncidentTab;
  activeName1: any = IncidentModule.selectedTab;
  get rightDashLoader() {
    return IncidentModule.RightDashLoader;
  }

  toggleClass() {
    this.isActive = !this.isActive;
    this.isVisible = !this.isVisible;
    eventHub.$emit("map-size-updated");
  }

  get status() {
    return IncidentStatus;
  }

  get routes() {
    return MetaModule.routesListOfUser;
  }

  onMessageButtonClicked() {
    //MessagingModule.selectIncident(e);
    eventHub.$emit("show-message-box");
  }

  async created() {
    await MetaModule.loadRoutesOfUser();
    if (MetaModule.routesListOfUser.length > 0) {
      this.activeName1 = String(MetaModule.routesListOfUser[0].gid);
      eventHub.$emit("tab-changed", this.activeName1);
      IncidentModule.setComplainOrIncident("incident");
      eventHub.$on("update-tab", this.updateTab);
      eventHub.$on("change-tab", this.changeTab);
    }
  }

  get IncidentOrComplain() {
    IncidentModule.setComplainOrIncident("incident");

    return IncidentModule.incidentOrComplain;
  }

  changeTab(e: IncidentStatus) {
    this.activeName1 = e;
    IncidentModule.setSelectedIncidentTab(this.activeName1);
    //  eventHub.$emit("incident-tab-changed", this.activeName);
  }
  updateTab(e: IncidentStatus) {
    this.activeName1 = e;
    IncidentModule.setSelectedIncidentTab(this.activeName);
    eventHub.$emit("tab-changed", this.activeName1);
  }

  handleClick(tab: any, event: any) {
    IncidentModule.setSelectedIncidentTab(this.activeName);
    eventHub.$emit("tab-changed", this.activeName1);
  }
}
