
























import { Component, Vue } from "vue-property-decorator";
import Mainmap from "@/views/incident/subComponents/map/mainMap.vue";
import MainDash from "@/views/incident/subComponents/mainDash.vue";
import Rightdash from "@/views/incident/rightdash.vue";
import { eventHub } from "@/utils/eventHub";
import { IncidentModule } from "../../store/modules";

@Component({
  components: {
    MainDash,
    // Mainmap,
    Rightdash,
  },
})
export default class Dashboard extends Vue {
  name = "Dashboard";
  isActive: boolean = false;
  isVisible: boolean = false;
  complainOrIncident: any = "incident";
  get rightDashLoader() {
    return IncidentModule.RightDashLoader;
  }

  toggleClass() {
    this.isActive = !this.isActive;
    this.isVisible = !this.isVisible;
    eventHub.$emit("map-size-updated");
  }
  created() {
    IncidentModule.setComplainOrIncident("incident");
  }

  get IncidentOrComplain() {
    IncidentModule.setComplainOrIncident("incident");

    return IncidentModule.incidentOrComplain;
  }
}
